<template>
  <div>
    <!-- Stats Card Horizontal -->
    <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="CpuIcon"
          :statistic="statistic.totalMoneyTrade ? statistic.totalMoneyTrade.toLocaleString() : 0"
          :statistic-title="$t('Tổng tiền giao dịch')"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="ServerIcon"
          color="success"
          :statistic="statistic.totalMoneyTradeSuccess.toLocaleString()"
          :statistic-title="$t('Tổng tiền thành công')"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="ActivityIcon"
          color="danger"
          :statistic="statistic.totalMoneyTradeFail.toLocaleString()"
          :statistic-title="$t('Tổng tiền thất bại')"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="AlertOctagonIcon"
          color="warning"

          :statistic="statistic.totalCountTrade"
          :statistic-title="$t('Tổng số giao dịch')"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="auto">
        <b-form-radio-group
          v-model="itemView"
          class="list item-view-radio-group"
          buttons
          size="sm"
          button-variant="outline-primary"
        >
          <b-form-radio
            v-for="option in itemViewOptions"
            :key="option.value"
            :value="option.value"
            class="btn-switch"
          >
            {{ option.text }}
            <feather-icon
              :icon="option.icon"
              size="18"
            />
          </b-form-radio>
        </b-form-radio-group>
      </b-col>

      <b-col>
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="searchKeyWord"
            :placeholder="$t('Mã giao dịch/nội dung/tài khoản nhận')"
          />
        </b-input-group>
      </b-col>

      <b-col>
        <b-form-datepicker
          v-model="dateFrom"
          local="vn"
          :placeholder="$t('Từ ngày')"
          selected-variant="primary"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          :max="today"
          style="margin-bottom: 0.2rem;"
        />
        <b-form-timepicker
          v-model="timeFrom"
          :disabled="dateFrom === null"
          locale="en"
          placeholder="Time From"
        />
      </b-col>

      <b-col>
        <b-form-datepicker
          v-model="dateTo"
          local="vn"
          :placeholder="$t('Đến ngày')"
          selected-variant="primary"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          :min="dateFrom"
          :max="today"
          style="margin-bottom: 0.2rem;"
        />
        <b-form-timepicker
          v-model="timeTo"
          :disabled="dateTo === null"
          locale="en"
          placeholder="Time To"
        />
      </b-col>

      <b-col>
        <b-form-group>
          <v-select
            v-model="payGate"
            :placeholder="$t('Cổng TT')"
            label="text"
            :options="payGateOptions"
            :reduce="i => i.value"
          />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <v-select
            v-model="orderStatus"
            :placeholder="$t('Trạng thái')"
            label="text"
            :options="orderStatusOptions"
            :reduce="status => status.value"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row align-h="end">
      <b-col>
        <b-button
          variant="primary"
          @click="fetchOrderPayIn(true)"
        >
          {{ $t('Tìm kiếm') }}
        </b-button>
        <b-button
          variant="outline-info"
          class="ml-1 float-right"
          :disabled="isExportLoading"
          @click="exportExcel"
        >
          <feather-icon
            v-if="!isExportLoading"
            icon="DownloadIcon"
          />
          <b-spinner
            v-else
            small
          />
          <span v-if="isExportLoading">{{ $t('Processing...') }} </span>
          <span v-else>{{ $t('Export Excel') }} </span>
        </b-button>
      </b-col>
    </b-row>
    <b-table
      small
      striped
      hover
      :fields="fields"
      :items="items"
      class="mt-2"
      show-empty
      :busy="isLoading"
      outlined
      responsive
      head-variant="light"
    >
      <!-- A virtual column -->
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>

      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" />
          <strong>Loading...</strong>
        </div>
      </template>

      <template #empty="scope">
        <b-row>
          <b-col>
            <b-alert
              show
              variant="info"
              class="text-center"
            >
              {{ $t('Không có dữ liệu') }}
            </b-alert>
          </b-col>
        </b-row>
      </template>

      <template #cell(transactionId)="data">
        <span
          class="text-nowrap"
        >{{ data.value }}</span>
      </template>

      <template #cell(createdAt)="data">
        <span class="text-nowrap">{{ formatDateTimeDb(data.value) }}</span>
      </template>

      <template #cell(order_status)="data">
        <span class="text-nowrap">{{ data.value || $t('Đang xử lý') }}</span>
      </template>

      <template #cell(updatedAt)="data">
        <span class="text-nowrap">{{ isPending(data.item) ? '_' : formatDateTimeDb(data.value) }}</span>
      </template>

      <template #cell(payGateName)="data">
        <span class="text-nowrap">{{ mapPort(data.value) }}</span>
      </template>

      <template #cell(receiver)="data">
        <span class="text-nowrap">{{ data.value ? data.value : data.item.detail.mobileOperator }}</span>
      </template>

      <template #cell(detail.content)="data">
        <span
          v-if="data.value"
          class="text-nowrap"
        >
          {{ data.value }}
        </span>
        <span
          v-else
          class="text-nowrap"
        >
          {{ data.item.detail.cardCode }} <br> {{ data.item.detail.cardSeri }}
        </span>
      </template>

      <template #cell(status)="data">
        <b-badge :variant="mapVariant(data.value)">
          {{ mapStatus(data.value) }}
        </b-badge>
      </template>

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>
    </b-table>

    <b-row>
      <b-col class="d-flex justify-content-md-end">
        <b-pagination
          v-model="pagination.current_page"
          :total-rows="pagination.total"
          :per-page="pagination.per_page"
          :first-text="$t('Trang đầu')"
          :prev-text="$t('Trang trước')"
          :next-text="$t('Trang tiếp')"
          :last-text="$t('Trang cuối')"
          @change="changePage"
        />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BPagination,
  BFormDatepicker,
  BFormGroup,
  BTable,
  BBadge,
  BAlert,
  BSpinner,
  BFormTimepicker,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import { watch, onMounted, ref } from '@vue/composition-api'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
import { formatDateTimeDb } from '@/libs/timezone'
// eslint-disable-next-line import/no-cycle
import dateFormat from 'dateformat'
// eslint-disable-next-line import/no-cycle
import { useOrderPayInUi, useOderPayInFilter, useMasterFilter } from './useOrder'

export default {
  name: 'Payin',
  components: {
    BRow,
    BCol,
    BFormRadioGroup,
    BFormRadio,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BFormDatepicker,
    BFormGroup,
    BButton,
    BPagination,
    BTable,
    BBadge,
    BAlert,
    BSpinner,
    vSelect,
    StatisticCardHorizontal,
    BFormTimepicker,
  },
  setup() {
    const { router } = useRouter()

    const {
      itemView,
      itemViewOptions,
    } = useOrderPayInUi()

    const {
      fields,
      items,
      pagination,
      searchKeyWord,
      handleSearch,
      mapStatus,
      mapVariant,
      mapPort,
    } = useOderPayInFilter()

    const {
      dateFrom,
      dateTo,
      merchantName,
      payGate,
      orderStatus,
      merchantNameOptions,
      payGateOptions,
      orderStatusOptions,
      statistic,
      fetchMerchantNameOptions,
      // fetchPayGateOptions,
      fetchStatistic,
    } = useMasterFilter()

    const isLoading = ref(false)
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const timeFrom = ref(null)
    const timeTo = ref(null)
    const isExportLoading = ref(false)

    const parseDateTime = (date, time, type) => {
      let timeParse
      let second = 0
      if (!date) return null
      if (type === 'from') {
        timeParse = time || '00:00'
      } else {
        timeParse = time || '23:59'
        second = 59
      }
      const dateArr = date.split('-')
      const timeArr = timeParse.split(':')
      return dateFormat(new Date(dateArr[0], dateArr[1] - 1, dateArr[2], timeArr[0], timeArr[1], second), 'yyyy-mm-dd HH:MM:ss')
    }

    const fetchOrderPayIn = (isSearch = false) => {
      isLoading.value = true
      const searchParams = {
        searchKeyWord: searchKeyWord.value.trim(),
        page: !isSearch ? pagination.value.current_page : null,
        dateFrom: parseDateTime(dateFrom.value, timeFrom.value, 'from'),
        dateTo: parseDateTime(dateTo.value, timeTo.value, 'to'),
        payGateId: payGate.value,
        status: orderStatus.value,
      }
      handleSearch(searchParams).then(res => {
        const { data, meta } = res
        items.value = data
        pagination.value = meta.pagination
        isLoading.value = false
      })
    }

    const fetchOrderPayInStatistic = () => {
      fetchStatistic({
        type: 'payin',
      }).then(res => {
        const { data } = res
        statistic.value = data
      })
    }

    const changePage = page => {
      pagination.value.current_page = page
      fetchOrderPayIn()
    }

    const isPending = order => order.status === 'pending'

    onMounted(() => {
      fetchOrderPayIn()
      fetchOrderPayInStatistic()
      fetchMerchantNameOptions()
      // fetchPayGateOptions()
    })

    watch([itemView], change => {
      if (change[0] === 'pay-out') {
        router.push('/order-management/pay-out')
      } else {
        router.push('/order-management/pay-in')
      }
    }, {
      deep: true,
    })

    return {
      isLoading,
      itemView,
      itemViewOptions,
      searchKeyWord,
      pagination,
      mapStatus,
      mapVariant,

      dateFrom,
      dateTo,
      merchantName,
      payGate,
      orderStatus,
      merchantNameOptions,
      payGateOptions,
      orderStatusOptions,
      statistic,
      formatDateTimeDb,
      timeFrom,
      timeTo,
      isExportLoading,

      fields,
      items,
      fetchOrderPayIn,
      changePage,
      isPending,
      mapPort,
      today,
      parseDateTime,
    }
  },
  methods: {
    exportExcel() {
      if (!this.isTimeVerify()) {
        this.$swal(this.$t('Thông báo'), this.$t('Chưa chọn thời gian hoặc khoảng thời gian lớn hơn 30 ngày'), 'warning')
        return
      }
      this.isExportLoading = true
      this.$http.get('/export/order', {
        responseType: 'blob',
        params: {
          searchKeyWord: this.searchKeyWord.trim(),
          dateFrom: this.parseDateTime(this.dateFrom, this.timeFrom, 'from'),
          dateTo: this.parseDateTime(this.dateTo, this.timeTo, 'to'),
          payGateId: this.payGate,
          status: this.orderStatus,
        },
        headers: {
          lang: this.$i18n.locale,
        },
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/xls' })
          const link = document.createElement('a')
          const d = new Date()
          const dateString = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}-${d.getHours()}-${d.getMinutes()}`
          link.href = URL.createObjectURL(blob)
          link.download = `order-${dateString}.xlsx`
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
        .finally(() => {
          this.isExportLoading = false
        })
    },
    isTimeVerify() {
      if (this.dateFrom && this.dateTo) {
        const dateFrom = new Date(this.dateFrom)
        const dateTo = new Date(this.dateTo)
        const diffTime = Math.abs(dateTo - dateFrom)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        return diffDays <= 30
      }
      return false
    },
  },
}
</script>

<style scoped>
.btn-switch.active {
  color: white !important;
  background: #3A57E8 !important;
}
.btn-switch {
  color: #6e6b7b !important;
  background: white !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 25px); /* change this to `- 40px` if you're supporting a `clearable` field; I was not */
}

.vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
</style>
